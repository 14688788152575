<template>
  <div>
    <DeleteModal
      :is-delete-modal-active.sync="isDeleteModalActive"
      :items="deleteItem"
      @confirm-delete="confirmDelete($event)" />
    <UserAdd
      ref="userAdd"
      :user-team="userTeam"
      :is-sidebar-active.sync="isSidebarActive"
      @add-user="addUser" />
    <UserDetail
      :is-sidebar-active.sync="isSidebarDetailActive"
      :initial-user="initialUser"
      :selected-item.sync="selectedItem"
      :user-team="userTeam"
      @update-user="updateUser($event)"
      @delete-item="openDeleteModal($event)" />
    <UserFilter
      :search-user-id.sync="filter.searchCodeAndName"
      :search-user-name.sync="filter.searchFullName"
      :search-user-tel.sync="filter.searchTel"
      :search-user-email.sync="filter.searchEmail"
      :search-user-user-name.sync="filter.searchUserName"
      :search-user-user-team.sync="filter.searchUserTeam"
      :status-filter.sync="filter.status"
      :role-filter.sync="filter.role"
      @fetch-filter="fetchFilter()" />
    <b-card class="mt-2">
      <TableTop
        v-model="pagination.limit"
        label="Add User"
        @active-sidebar="activeSidebar()"
        @change="fetchFilter()" />
      <b-table
        striped
        hover
        responsive
        :items="items"
        :fields="fields"
        @row-clicked="rowClick($event)">
        <template #cell(code)="data">
          <span class="font-weight-semibold text-primary">#{{ data.value }}</span>
        </template>
        <template #cell(name)="{ item }">
          <span class="font-weight-semibold d-block">{{ item.firstName }} {{ item.lastName }}</span>
        </template>
        <template #cell(contact)="{ item }">
          <span class="d-block">{{ item.email }}</span>
          <span class="d-block">{{ item.tel }}</span>
        </template>
        <template #cell(status)="data">
          <b-badge :variant="$statusValue(data.value)">
            {{ data.value | formatStatusData }}
          </b-badge>
        </template>
      </b-table>
      <b-row>
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
            v-model="pagination.page"
            :total-rows="pagination.totalDocs"
            :per-page="pagination.limit"
            first-number
            last-number
            class="mb-1 mt-2 mr-1"
            prev-class="prev-item"
            next-class="next-item"
            @change="showCurrentPage($event)">
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18" />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import UserAdd from '@/components/Users/User/UserAdd.vue'
import UserDetail from '@/components/Users/User/UserDetail.vue'
import UserFilter from '@/components/Users/User/UserFilter.vue'
import DeleteModal from '@/components/DeleteModal.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TableTop from '@/components/TableTop.vue'
import UserProvider from '@/resources/UserProvider'
import UserTeamProvider from '@/resources/TeamProvider'

const UserService = new UserProvider()
const UserTeamService = new UserTeamProvider()

export default {
  components: {
    UserAdd,
    UserDetail,
    DeleteModal,
    UserFilter,
    TableTop
  },
  data () {
    return {
      isSidebarActive: false,
      isSidebarDetailActive: false,
      isDeleteModalActive: false,
      filter: {
        searchCodeAndName: '',
        searchFullName: '',
        searchTel: '',
        searchEmail: '',
        searchUserName: '',
        searchUserTeam: '',
        status: '',
        role: ''
      },
      pagination: {
        page: 1,
        limit: 10,
        totalDocs: 10
      },
      fields: [
        { key: 'code', label: 'Code', sortable: true },
        { key: 'name', label: 'Name' },
        { key: 'contact', label: 'Contact' },
        { key: 'role', label: 'Role' },
        { key: 'userTeam.name', label: 'Team' },
        { key: 'status', label: 'Status' }
      ],
      items: [],
      initialUser: {},
      selectedItem: {},
      deleteItem: {},
      userTeam: []
    }
  },
  async created () {
    await this.getUserInfo()
    await this.getUserTeam()
  },
  methods: {
    async getUserInfo () {
      try {
        const data = await UserService.paginate(this.pagination.page, this.pagination.limit, this.filter)
        this.setPagination(data.page, data.totalDocs)
        this.items = [...data.data]
      } catch (err) {
        console.error(err)
      }
    },
    async getUserTeam () {
      try {
        const { data } = await UserTeamService.findAllTeamName()
        this.userTeam = data
      } catch (err) {
        console.error(err)
      }
    },
    async addUser (val) {
      try {
        await UserService.createGroup(val)
        this.setPagination(1)
        this.resetFormAndToggleSideBar(this.$refs.userAdd)
        await this.getUserInfo()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Record Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 User Group has been added'
          }
        })
      } catch (err) {
        if (err && err?.statusCode === 409) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed: Data is duplicated',
              icon: 'EditIcon',
              variant: 'danger',
              text: 'code or email is duplicated'
            }
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error: System Error',
              icon: 'EditIcon',
              variant: 'danger',
              text: 'Please try again later.'
            }
          })
        }
      }
    },
    async updateUser (val) {
      try {
        const payload = { ...val }
        delete payload.id
        const { data } = await UserService.update(val.id, payload)
        if (data && data?.password) {
          delete data?.password
        }
        await this.getUserInfo()
        this.setInitialUserAndSelectItem(data)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Updated Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 User has been updated'
          }
        })
      } catch (err) {
        if (err && err?.statusCode === 409) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed: Data is duplicated',
              icon: 'EditIcon',
              variant: 'danger',
              text: 'code or email is duplicated'
            }
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error: System Error',
              icon: 'EditIcon',
              variant: 'danger',
              text: 'Please try again later.'
            }
          })
        }
      }
    },
    async confirmDelete (val) {
      try {
        this.isSidebarDetailActive = false
        await UserService.deleteGroup(val.ID)
        await this.getUserInfo()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: `👋 User ${this.deleteItem.Name} has been deleted`
          }
        })
      } catch (error) {
        console.error(error)
      }
    },
    activeSidebar () {
      this.isSidebarActive = true
    },
    rowClick (val) {
      this.setInitialUserAndSelectItem(val)
      this.isSidebarDetailActive = true
    },
    setInitialUserAndSelectItem (val) {
      this.selectedItem = { ...val }
      this.initialUser = { ...val }
    },
    openDeleteModal (val) {
      this.deleteItem = { ...val }
      this.isDeleteModalActive = true
    },
    fetchFilter () {
      this.setPagination(1)
      this.getUserInfo()
    },
    resetFormAndToggleSideBar (ref) {
      this.isSidebarActive = !this.isSidebarActive
      if (ref && ref.resetForm()) ref.resetForm()
    },
    showCurrentPage (page) {
      this.setPagination(page)
      this.getUserInfo()
    },
    setPagination (page, totalDocs) {
      this.pagination.page = page || this.pagination.page
      this.pagination.totalDocs = totalDocs === 0 ? 0 : totalDocs || this.pagination.totalDocs
    }
  }
}
</script>

<style lang="scss" scoped>
.v-select {
  background: white;
}
.card-body {
  padding: 0;
}
::v-deep input.vs__search::placeholder {
  color: #B9B9C3;
}
</style>
