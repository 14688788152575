<template>
  <b-form-group
    class="font-weight-semibold mb-0"
    label="Role">
    <v-select
      v-model="innerValue"
      v-bind="$attrs"
      :options="options"
      :reduce="val => val['value']"
      label="label"
      placeholder="Role"
      clearable
      v-on="{...$listeners}"
    />
  </b-form-group>
</template>

<script>
export default {
  props: {
    roleFilter: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      options: [
        { label: 'Agent', value: 'agent' },
        { label: 'Supervisor', value: 'supervisor' },
        { label: 'Manager', value: 'manager' },
        { label: 'Super Admin', value: 'super admin' }
      ]
    }
  },
  computed: {
    innerValue: {
      get () {
        return this.roleFilter
      },
      set (val) {
        this.$emit('update:role-filter', val)
        this.$emit('change-role-filter')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-select {
  background: white;
}
::v-deep input.vs__search::placeholder {
  color: #B9B9C3;
}
</style>
